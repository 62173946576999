<template>
  <div v-if="this.$store.state.loggedIn" id="hide">
    <div v-if="collections.length > 0" id="hide">
      <table border="0" width="90%" class="center">
        <tr><th>Collection</th><th>Number of Records</th></tr>
        <tr v-for="item in collections" :key="item.id">
          <td><a v-bind:href="'/folder/'+item.id">{{ item.name }}</a></td>
          <td>{{ item.instances.length }}</td>
        </tr>
      </table>
      </div>
  </div>
  <div v-else id="hide">Please login to continue.</div>
</template>

<style scoped>
</style>

<script>
export default {
  data() {
    return {
      collections: [],
    };
  },
  beforeMount() {
    this.getCollections();
  },
  methods: {
    async getCollections() {
      this.loading = true;
      const stoid = this.$cookie.get("sto-login");
      if (stoid.length > 0) {
        const request = new Request(
          this.$apihost + "/straightenthemout.STOService/GetCollections",
          {
            method: "POST",
            mode: "cors",
            cache: "default",
            body: JSON.stringify({
              stoid: stoid,
            }),
          }
        );
        try {
          const res = await fetch(request);
          const data = await res.json();
          this.collections = data.collections;
        } catch (p) {
          console.log("Error in getting inventory: " + p);
        }
      }
    },
  },
};
</script>
import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from './router'

var VueCookie = require('vue-cookie');

Vue.use(VueCookie);
Vue.use(Vuex)

Vue.config.productionTip = true
Vue.prototype.$apihost = (Vue.config.productionTip) ? 'https://straightenthemout-qo2wxnmyfq-uw.a.run.app' : 'http://toru:50020'


const store = new Vuex.Store({
  state: {
    loggedIn: false,
  },
  mutations: {
    logout(state) {
      state.loggedIn = false
    },
    login(state) {
      state.loggedIn = true
    }
  }
})

new Vue({
  store: store,
  router,
  render: h => h(App),
}).$mount('#app')

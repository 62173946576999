<template>
  <div v-if="this.$store.state.loggedIn" id="hide">
      <h3>{{ folder_name }} {{this.arrangement}}</h3>
      <div v-if="records.length > 0" id="hide">
          <table border="0" width="90%" class="center">
            <tr><th>Slot</th><th>Artist</th><th>Title</th></tr>
              <tr v-for="item in records" :key="item.Id">
                <td>{{item.slot}}</td>
                <td>{{item.artist}}</td>
                <td v-if="item.hasWidth">{{item.title}}</td>
                <td v-else><b>{{item.title}}</b></td>
              </tr>
          </table>
       </div>
    </div>
</template>

<style scoped>
</style>

<script>
export default {
  data() {
    return {
        folder_name: "",
        records: [],
        totalSlots: 4,
        totalWidth: 0,
        currentSlot: 1,
        ordered: [],
    };
  },
  computed: {
  
  },
  filters: {
  },
  props: {
    fid: String,
    arrangement: String,
  },
  beforeMount() {
    this.getFolder(this.fid, this.arrangement);
  },
  methods: {
    async getFolder(fid, arrangement) {
      this.loading = true;
      const stoid = this.$cookie.get("sto-login");
      if (stoid.length > 0) {
        const request = new Request(
          this.$apihost + "/straightenthemout.STOService/GetCollection",
          {
            method: "POST",
            mode: "cors",
            cache: "default",
            body: JSON.stringify({
              stoid: stoid,
              collection_id: fid,
              arrangement_id: arrangement,
            }),
          }
        );
        try {
          const res = await fetch(request);
          const data = await res.json();
          this.folder_name = data.name
          this.records = data.records
          console.log(JSON.stringify(this.records[0]))
        } catch (p) {
          console.log("Error in getting inventory: " + p);
        }
      }
    },
  },
};
</script>
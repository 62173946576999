<template>
  <div id="pull">
    <div v-if="this.$store.state.loggedIn" id="hide">
      <collections />
    </div>
    <div v-else id="hide">This is Straighten Them Out</div>
  </div>
</template>

<script>
import Collections from "./Collections.vue";
export default {
  name: "pull",
  components: {
    Collections,
  },
};
</script>
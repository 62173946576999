import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../components/Main.vue'
import FakeLogin from '../components/FakeLogin.vue'
import Folder from '../components/Folder.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Main
    },
    /*{
        path: '/oauth_callback',
        name: 'Callback',
        component: Callback,
        props: (route) => ({ code: route.query.code, })
    },*/
    {
        path: '/fakelogin',
        name: 'FakeLogin',
        component: FakeLogin,
        props: (route) => ({ cookie: route.query.cookie })
    },
    {
        path: '/folder/:fid',
        name: 'Folder',
        component: Folder,
        props: (route) => ({fid: route.params.fid, arrangement: route.query.arrangement})
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router